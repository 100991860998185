/* .column {
  float: left;
  width: 50%;
  word-wrap: break-word;
}

.row:after {
  content: '';
  display: table;
  clear: both;

} */

.leaflet-container1 {
  height: 600px !important;
}

.map-card{
    background-color: #FFFFFF;
    border-radius: 10px;
  }
  
  .completedClass {
    background-color: #e8fff3;
    padding-bottom: 2px;
    padding-top: 1px;
    color: #50cd89;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .ongoingClass {
    background-color: #fffaeb;
    padding-bottom: 2px;
    padding-top: 1px;
    color: #f2c94c;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .cancelledClass {
    background-color: #fff5f8;
    padding-bottom: 2px;
    padding-top: 1px;
    color: #f1416c;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
  }