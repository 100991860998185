.popup-container {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  /* Style for input fields */
  .popup-input {
    width: calc(100% - 10px);
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style for select element */
  .popup-select {
    width: calc(100% - 10px);
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style for submit button */
  .popup-submit {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  .popup {
    position: absolute;
    top: 75%;
    left: 60%;
    transform: translate(-50%, -50%);
}
  
  .popup label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .popup input[type="text"] {
    width: 100%;
    padding: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .popup button {
    padding: 0.25rem 0.5rem;
    background-color: #5F73E3;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .popup button:hover {
    background-color: #5F73E3;
  }

  .blur {
    filter: blur(4px); /* Adjust the blur intensity as needed */
}

.disable-pointer-events {
    pointer-events: none;
}